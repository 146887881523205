import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { Hero } from "../components/Hero/Hero";
import { Card } from "../components/Card/Card";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CorporateValues = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("corporate-values.hero.title")}
      description={t("corporate-values.hero.subtitle")}
    >
      <Hero
        title={t("corporate-values.hero.title")}
        description={t("corporate-values.hero.subtitle")}
        image="corporate-values/corporate-values-hero.jpg"
        withSpacing
      />
      <Container>
        <Column
          columns_mobile="12"
          columns_tablet="12"
          columns_desktop="12"
          custom_class="no-gutter@lg"
        >
          <Card
            title={t("corporate-values.our-values.title")}
            description={t("corporate-values.our-values.subtitle")}
            image="corporate-values/our-values.jpg"
          />

          <Card
            title={t("corporate-values.corporate-structure.title")}
            description={t("corporate-values.corporate-structure.text1")}
            image="corporate-values/corporate-structure.jpg"
            reverse
          />
          <Card
            title={t("corporate-values.our-vision.title")}
            description={t("corporate-values.our-vision.text1")}
            image="corporate-values/our-vision.jpg"
          />
        </Column>
        <Column columns_mobile="12" columns_tablet="6" columns_desktop="3">
          <Card
            valueCard
            title={t("corporate-values.values.excellence.title")}
            description={t("corporate-values.values.excellence.description")}
            image="icons/excellence-badge.jpg"
          />
        </Column>
        <Column columns_mobile="12" columns_tablet="6" columns_desktop="3">
          <Card
            valueCard
            title={t("corporate-values.values.quality.title")}
            description={t("corporate-values.values.quality.description")}
            image="icons/magnifier.jpg"
          />
        </Column>
        <Column columns_mobile="12" columns_tablet="6" columns_desktop="3">
          <Card
            valueCard
            title={t("corporate-values.values.partnership.title")}
            description={t("corporate-values.values.partnership.description")}
            image="icons/paperclip.jpg"
          />
        </Column>
        <Column columns_mobile="12" columns_tablet="6" columns_desktop="3">
          <Card
            valueCard
            title={t("corporate-values.values.innovation.title")}
            description={t("corporate-values.values.innovation.description")}
            image="icons/brain.jpg"
          />
        </Column>
      </Container>
    </Layout>
  );
};

export default CorporateValues;
